import React, { useEffect } from "react"

const GdprPage = () => {
  const lang = (process.env.GATSBY_LANG || "it_IT").split("_")[0]

  useEffect(() => {
    window.location.replace(`/${lang}/gdpr`)
  }, [])

  return null
}

export default GdprPage
